// project import
// import pages from './pages'
import MemberMenu from './member-menu'

// ==============================|| MENU ITEMS ||============================== //

const menuItems = {
  items: [MemberMenu]
}

export default menuItems

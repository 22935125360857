import { createRoot } from 'react-dom/client'
import { BrowserRouter } from 'react-router-dom'

// scroll bar
import 'simplebar-react/dist/simplebar.min.css'
import 'slick-carousel/slick/slick.css'
import 'slick-carousel/slick/slick-theme.css'

// apex-chart
import 'assets/third-party/apex-chart.css'
import 'assets/third-party/react-table.css'
// third-party
import { Provider as ReduxProvider } from 'react-redux'

// google-fonts
import '@fontsource/roboto/400.css'
import '@fontsource/roboto/500.css'
import '@fontsource/roboto/300.css'
import '@fontsource/roboto/700.css'

import '@fontsource/inter/400.css'
import '@fontsource/inter/500.css'
import '@fontsource/inter/600.css'
import '@fontsource/inter/700.css'

import '@fontsource/poppins/400.css'
import '@fontsource/poppins/500.css'
import '@fontsource/poppins/600.css'
import '@fontsource/poppins/700.css'

import '@fontsource/public-sans/400.css'
import '@fontsource/public-sans/500.css'
import '@fontsource/public-sans/600.css'
import '@fontsource/public-sans/700.css'

// project import
import App from './App'
import { store } from 'store'
import reportWebVitals from './reportWebVitals'
import { UserProvider } from "./context/UserContext"
import { AppointmentProvider} from "./context/AppointmentContext"
// ==============================|| MAIN - REACT DOM RENDER  ||============================== //

const container = document.getElementById('root')
const root = createRoot(container) // createRoot(container!) if you use TypeScript
root.render(
    <ReduxProvider store={store}>
        <UserProvider>
          <BrowserRouter basename="/">
              <AppointmentProvider>
                <App />
              </AppointmentProvider>
          </BrowserRouter>
        </UserProvider>
    </ReduxProvider>
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()

// material-ui
import { Box, Typography } from '@mui/material'

// project import
import NavGroup from './NavGroup'
import menuItem from 'menu-items'
import { useUserContext } from "../../../../../context/UserContext"

// ==============================|| DRAWER CONTENT - NAVIGATION ||============================== //

const Navigation = () => {
  const { getSessionUser } = useUserContext()
  const sessionUser = getSessionUser()
  const navGroups = menuItem.items.map((item) => {

    switch (item.type) {
      case 'group':
        return <NavGroup key={item.id} item={item} sessionUser={sessionUser}/>
      default:
        return (
          <Typography key={item.id} variant="h6" color="error" align="center">
            Fix - Navigation Group
          </Typography>
        )
    }
  })

  return <Box sx={{ pt: 2 }}>{navGroups}</Box>
}

export default Navigation

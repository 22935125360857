import _ from "lodash"

import {
    USER_TYPE_ADMIN,
    USER_TYPE_LICENCE_ADMIN,
    USER_TYPE_LICENCE_OWNER,
    USER_TYPE_MANAGER,
    USER_TYPE_PRACTITIONER,
    USER_TYPE_RECEIPTS,
    USER_TYPE_SUPERADMIN
} from "../constants/userConstants"

export default {
    checkItemAllow(sessionUser, path) {
        if (_.includes([USER_TYPE_SUPERADMIN, USER_TYPE_MANAGER], sessionUser?.typeId)) {
            const excludeList = ['wageBoard', 'timesheetBoard', 'commissionBoard']
            if (_.includes(excludeList, path)) {
                return false
            }
            return true
        }
        // for all member
        switch (path) {
            case 'help':
            case 'booking':
            case 'general':
            case 'public':
            case 'logout':
            case '404':
                return true
        }
        let allowList = []
        switch (path) {
            case 'dashboard':
                allowList = [
                    USER_TYPE_RECEIPTS,
                    USER_TYPE_MANAGER
                ]
                break
            case 'schedule':
                allowList = [
                    USER_TYPE_PRACTITIONER,
                    USER_TYPE_RECEIPTS,
                    USER_TYPE_MANAGER
                ]
                break
            case 'appointment':
                allowList = [
                    USER_TYPE_RECEIPTS,
                    USER_TYPE_MANAGER
                ]
                break
            case 'insuranceSchedule':
                allowList = [
                    USER_TYPE_RECEIPTS,
                    USER_TYPE_MANAGER,
                    USER_TYPE_LICENCE_OWNER
                ]
                break
            case 'licenceSchedule':
                allowList = [
                    USER_TYPE_RECEIPTS,
                    USER_TYPE_MANAGER,
                    USER_TYPE_LICENCE_OWNER,
                    USER_TYPE_LICENCE_ADMIN
                ]
                break

            case 'bills':
            case 'bill':
            case 'credits':
            case 'credit':
                allowList = [
                    USER_TYPE_RECEIPTS,
                    USER_TYPE_MANAGER
                ]
                break
            case 'receipts':
                allowList = [
                    USER_TYPE_RECEIPTS,
                    USER_TYPE_MANAGER,
                    USER_TYPE_LICENCE_OWNER
                ]
                break
            case 'receipt':
                allowList = [
                    USER_TYPE_RECEIPTS,
                    USER_TYPE_MANAGER
                ]
                break
            case 'orders':
            case 'order':
                allowList = [
                    USER_TYPE_RECEIPTS,
                    USER_TYPE_MANAGER
                ]
                break
            case 'licences':
                allowList = [
                    USER_TYPE_RECEIPTS,
                    USER_TYPE_MANAGER,
                    USER_TYPE_LICENCE_OWNER,
                    USER_TYPE_LICENCE_ADMIN
                ]
                break
            case 'clients':
            case 'client':
            case 'users':
            case 'user':
            case 'licence':
            case 'categories':
            case 'category':
            case 'products':
            case 'commission':
            case 'product':
            case 'billHistory':
            case 'orderHistory':
            case 'paymentHistory':
            case 'creditHistory':
            case 'treatmentHistory':
            case 'receiptHistory':
                allowList = [
                    USER_TYPE_RECEIPTS,
                    USER_TYPE_MANAGER
                ]
                break
            case 'wages':
                allowList = [
                    USER_TYPE_RECEIPTS,
                    USER_TYPE_MANAGER
                ]
                break
            case 'wage':
                allowList = [
                    USER_TYPE_RECEIPTS,
                    USER_TYPE_MANAGER
                ]
                break
            case 'wageBoard':
                allowList = [
                    USER_TYPE_PRACTITIONER
                ]
                break
            case 'timesheets':
                allowList = [
                    USER_TYPE_RECEIPTS,
                    USER_TYPE_MANAGER
                ]
                break
            case 'timesheetBoard':
                allowList = [
                    USER_TYPE_PRACTITIONER
                ]
                break
            case 'commissionBoard':
                allowList = [
                    USER_TYPE_PRACTITIONER
                ]
                break
            case 'timesheet':
                allowList = [
                    USER_TYPE_RECEIPTS,
                    USER_TYPE_MANAGER
                ]
                break
            // case 'managerZone':
            //     allowList = [
            //         USER_TYPE_MANAGER
            //     ]
            //     break
            // case 'practitioner':
            //     allowList = [
            //         USER_TYPE_PRACTITIONER,
            //         USER_TYPE_RECEIPTS
            //     ]
            //     break
            case 'setting':
                allowList = [
                    USER_TYPE_RECEIPTS,
                    USER_TYPE_MANAGER
                ]
                break
        }
        if (sessionUser?.typeId) {
            if (_.includes(allowList, sessionUser.typeId)) {
                return true
            } else {
                return false
            }
        } else {
            return false
        }
    },
    checkCollapseAllow(sessionUser, menu) {
        if (sessionUser?.typeId === USER_TYPE_SUPERADMIN) {
            return true
        }
        if (menu.id === 'memberMenu') {
            return true
        }
        let allowList = []
        switch (menu.id) {
            case 'appointmentsRoot':
                allowList = [
                    USER_TYPE_PRACTITIONER,
                    USER_TYPE_RECEIPTS,
                    USER_TYPE_MANAGER
                ]
                break
            case 'financesRoot':
                allowList = [
                    USER_TYPE_RECEIPTS,
                    USER_TYPE_MANAGER
                ]
                break
            case 'ordersRoot':
                allowList = [
                    USER_TYPE_RECEIPTS,
                    USER_TYPE_MANAGER
                ]
                break
            case 'licenceRoot':
                allowList = [
                    USER_TYPE_RECEIPTS,
                    USER_TYPE_MANAGER,
                    USER_TYPE_LICENCE_OWNER,
                    USER_TYPE_LICENCE_ADMIN
                ]
                break
            case 'clientRoot':
                allowList = [
                    USER_TYPE_RECEIPTS,
                    USER_TYPE_MANAGER
                ]
                break
            case 'wagesRoot':
                allowList = [
                    USER_TYPE_PRACTITIONER,
                    USER_TYPE_RECEIPTS,
                    USER_TYPE_MANAGER
                ]
                break

            case 'receptionistZoneRoot':
            case 'managerZone':
                allowList = [
                    USER_TYPE_RECEIPTS,
                    USER_TYPE_MANAGER
                ]
                break
        }
        if (sessionUser?.typeId) {
            if (_.includes(allowList, sessionUser.typeId)) {
                return true
            } else {
                return false
            }
        } else {
            return false
        }
    },

    async defaultLandingPage(sessionUser) {
        let home = '/dashboard'
        switch (sessionUser?.typeId) {
            case USER_TYPE_MANAGER:
            case USER_TYPE_SUPERADMIN:
            case USER_TYPE_PRACTITIONER:
                home = '/schedule'
                break
            case USER_TYPE_ADMIN:
                home = '/receipts'
                break
            case USER_TYPE_LICENCE_OWNER: {
                home = '/insuranceSchedule/' + sessionUser.id
                break
            }
            case USER_TYPE_LICENCE_ADMIN:
                home = '/licences'
        }
        return home
    }
}


import { lazy } from 'react'
import UnAuthGuard from "../guards/UnAuthGuard"

// project import
import Loadable from '../components/Loadable'
import MinimalLayout from '../layout/MinimalLayout'

// render - login
const AuthLogin = Loadable(lazy(() => import('pages/auth/Login')))
const ForgotPassword = Loadable(lazy(() => import('pages/auth/ForgotPassword')))
const CheckCode = Loadable(lazy(() => import('pages/auth/CheckCode')))
const ResetPassword = Loadable(lazy(() => import('pages/auth/ResetPassword')))

const BookingForm = Loadable(lazy(() => import('pages/booking/BookingForm')))
// ==============================|| AUTH ROUTING ||============================== //

const AnonymousRoutes = {
  path: '/',
  element: <MinimalLayout />,
  children: [
    {
      path: 'login',
      element: <UnAuthGuard component={<AuthLogin />} />
    },
    {
      path: 'forgotPassword',
      element: <UnAuthGuard component={<ForgotPassword />} />
    },
    {
      path: 'checkCode/:token',
      element: <UnAuthGuard component={<CheckCode />} />
    },
    {
      path: 'resetPassword/:token/:code',
      element: <UnAuthGuard component={<ResetPassword />} />
    },
    {
      path: 'Booking',
      element: <UnAuthGuard component={<BookingForm />} />
    }
  ]
}

export default AnonymousRoutes
